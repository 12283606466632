import type { GetServerSideProps } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface PageProps {
  isUser: boolean
  isSponsor: boolean
}

const Home: FC<PageProps> = ({ isUser, isSponsor }) => {
  const { query } = useRouter()
  return (
    <>
      <Head>
        <title>VSE</title>
      </Head>
    </>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      isUser: false,
      isSponsor: false,
    },
  }
}
